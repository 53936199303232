<template>
  <el-row>
    <el-col :span="12">
      <div class="editorContent">
        <WangEditor ref="editorModel"> </WangEditor>
      </div>
    </el-col>
    <el-col :span="12">
      <div class="select_area">
        <el-select
          v-model="LanguageOpt"
          placeholder="Select"
          style="width: 115px"
          @change="CheckedLanguage"
        >
          <el-option
            v-for="(item, index) in Languages"
            :key="index"
            :label="item.Name"
            :value="item.Id"
          />
        </el-select>
        <!-- <el-button type="primary" style="width: 100px" @click="CheckedLanguage">
          查询
        </el-button> -->
          <el-button @click="SaveConfig" type="success" style="width: 100px">
          保存
        </el-button>
      </div>

    </el-col>
  </el-row>
</template>
<script >
import axios from "../../../commons/AxiosMethod.js"
import {
  UploadFilled,
  Picture as IconPicture,
  Plus
} from "@element-plus/icons-vue"
import { ElMessageBox, ElMessage } from "element-plus"
import WangEditor from "../../../components/WangEditor.vue"

export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data () {
    return {
      HomeCarouselAdvertsDisplay: false,
      Key: "sys.phone.shiyongshuoming",
      ConfigModel: {},
      Languages: [],
      LanguageOpt: ""
    }
  },
  name: "App",
  mounted () {
    this.UploadImageUrl = axios.GetUrl() + "/api/Upload/ImageFile"
    this.headersImage = {
      Authorization: localStorage.getItem("Authentication_Header")
    }

    this.GetLanguages()
  },
  components: {
    WangEditor,
    UploadFilled,
    IconPicture,
    Plus
  },

  methods: {
    CheckedLanguage () {
      this.GetConfig(this.Key)
    },
    // 获取语言
    GetLanguages () {
      axios.apiMethod("/admins/Languages/GetLanguages", "get", {}, (result) => {
        this.Languages = result.Data
        this.Languages.forEach((e) => {
          if (e.Default) {
            this.LanguageOpt = e.Id
            this.GetConfig(this.Key)
          }
        })
      })
    },
    GetConfig (key) {
      axios.apiMethod(
        "/api/SystemConfiguration/GetConfigurationsByKey",
        "get",
        { Key: key + this.LanguageOpt },
        (result) => {
          this.ConfigModel = result.Data
          const editorModel = this.$refs.editorModel
          editorModel.editor.txt.html("")
          editorModel.editor.txt.html(this.ConfigModel.Value)
        }
      )
    },
    SaveConfig () {
      this.ConfigModel.Name = this.LanguageOpt + "多语言主键"
      const editorModel = this.$refs.editorModel
      var content = editorModel.editor.txt.html()
      this.ConfigModel.Value = content
      axios.apiMethod(
        "/api/SystemConfiguration/AddOrUpdateConfigurations",
        "post",
        this.ConfigModel,
        (result) => {
          if (result.Code == 200) {
            ElMessage({
              message: "修改成功",
              type: "success"
            })
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error"
            })
          }
        }
      )
    },
    GetAbout () {
      axios.apiMethod("/Admins/Advert/GetAbout", "get", {}, (result) => {
        if (result.Code == 200) {
          const editorModel = this.$refs.editorModel
          editorModel.editor.txt.html("")
          editorModel.editor.txt.html(result.Data.Content)
        } else {
          ElMessageBox({
            message: result.FullMsg,
            type: "error"
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.editorContent {
  width: 480px;
  min-height: 800px;
}
.saveConfig {
  text-align: center;
  margin: 0 auto;
}
.select_area {
  margin-top: 100px;
  width: 500px;
  display: flex;
  justify-items: center;
  justify-content: space-between;
}
</style>
